import React, { useEffect } from "react";
import LeaderBoardInfo from "./LeaderBoardInfo";
import { useLocation } from "react-router";
import { func } from "../../../Utilities/logFunc";

const LeaderBoard = () => {
  const location = useLocation()
  useEffect(() => {
    // Load the GPT library
    const gptScript = document.createElement('script');
    gptScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'; 
    gptScript.async = true;
    document.body.appendChild(gptScript);

    // Initialize the ad slot after the script loads
    gptScript.onload = () => {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.cmd.push(() => {
        window.googletag.defineSlot(
          '/23081990290,23206251223/gamerji.com.Banner0.1732793580', 
          [[970,90],"fluid"],
          'gpt-passback-gamerji.com.Banner0.1732793580'
        ).addService(window.googletag.pubads());

        window.googletag.enableServices();
        window.googletag.display('gpt-passback-gamerji.com.Banner0.1732793580');
        
        // // Set state to true once the ad is displayed
      // setIsAdLoaded(true);
      });
    };

    // Clean up the script tag on component unmount
    return () => {
      document.body.removeChild(gptScript);
    };
  }, []);
  func("location", window.location.pathname.split('/'));
  return (
    <div class="content">

<div className="content-inner">
      <div class="row gy-4">
        <LeaderBoardInfo />
      </div>
      </div>
      <div className="d-flex justify-content-center align-content-center">
        <div
          id="gpt-passback-gamerji.com.Banner0.1732793580"
          style={{
            width: '70%',
            height: 'auto',
            position: "fixed",
            bottom: '0',
            zIndex: '10',
            // background: "red",
          }}
        >
        </div>
        </div>
    </div>
  );
};

export default LeaderBoard;
