import React, { useEffect } from "react";
import FeaturedVideos from "./FeaturedVideos";
import PopulatVideos from "./PopulatVideos";
import TopProfiles from "./TopProfiles";
import News from "./News";

import { func } from "../../../Utilities/logFunc";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 
const WorldOfEsports = ({ handleClickBlog }) => {
  useEffect(()=>{
    EventTracking(); 
  },[]);
  const EventTracking=()=>{
    eventTracking(events.MENU.WOE);
  }
  useEffect(() => {
    // Load the GPT library
    const gptScript = document.createElement('script');
    gptScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'; 
    gptScript.async = true;
    document.body.appendChild(gptScript);

    // Initialize the ad slot after the script loads
    gptScript.onload = () => {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.cmd.push(() => {
        window.googletag.defineSlot(
          '/23081990290,23206251223/gamerji.com.Banner0.1732101929', 
          [[300,50],[300,100],[320,50],[320,100],[728,90],"fluid"], 
          'gpt-passback-gamerji.com.Banner0.1732101929'
        ).addService(window.googletag.pubads());

        window.googletag.enableServices();
        window.googletag.display('gpt-passback-gamerji.com.Banner0.1732101929');
        
        // // Set state to true once the ad is displayed
      // setIsAdLoaded(true);
      });
    };

    // Clean up the script tag on component unmount
    return () => {
      document.body.removeChild(gptScript);
    };
  }, []);

  func("location", window.location.pathname.split('/'));
  return (
    
    <div class="row gy-5">
        <div className="col-12 testadds mt-4">
        <div
          id="gpt-passback-gamerji.com.Banner0.1732101929"
          style={{
            width: '100%',
            height: "auto",
            bottom: '0',
            zIndex: '9999',
          //  background: "red",
          }}
        >
          {/* The ad slot will be displayed here */}
        </div>
        </div>
     
      <FeaturedVideos />
      <PopulatVideos />
      <TopProfiles />
      <News handleClickBlog={handleClickBlog} />
    </div>
  );
};

export default WorldOfEsports;
