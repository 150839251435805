import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { func } from "../../../Utilities/logFunc";
const QualificationModal = ({
  showQualificationModal,
  setShowQualificationModal,
  qualificationData,
}) => {
  const [show, setShow] = useState(showQualificationModal);

  const handleClose = () => {
    setShowQualificationModal((prev) => false);
    setShow(false);
  };
  const {t} = useTranslation()
  useEffect(() => {
    // Load the GPT library
    const gptScript = document.createElement('script');
    gptScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'; 
    gptScript.async = true;
    document.body.appendChild(gptScript);
  
    // Initialize the ad slot after the script loads
    gptScript.onload = () => {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.cmd.push(() => {
        window.googletag.defineSlot(
          '/23081990290,23206251223/gamerji.com.Banner0.1732793637', 
          [[728,90],"fluid"],
          'gpt-passback-gamerji.com.Banner0.1732793637'
        ).addService(window.googletag.pubads());
  
        window.googletag.enableServices();
        window.googletag.display('gpt-passback-gamerji.com.Banner0.1732793637');
        
        // // Set state to true once the ad is displayed
      // setIsAdLoaded(true);
      });
    };
  
    // Clean up the script tag on component unmount
    return () => {
      document.body.removeChild(gptScript);
    };
  }, []);
  func("location", window.location.pathname.split('/'));
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="row justify-content-center">
          <div class="col-11">
            <div class="title">
              <h3 class="text-center bodyBoldFont mb-5 pb-1 text-dark">{t('contest_details.qualification')}</h3>
            </div>
            <div class="text-center">
              <div class="row text-dark">
                <div class="col-6 text-start">
                  <h4 class="bodyBoldFont mb-0">
                    Rank 1 - {qualificationData?.winnerCount || 0}
                  </h4>
                </div>
                <div class="col-6 text-end">
                  <h4 class="bodyBoldFont mb-0">{t('contest_details.qualified_for_next_round')}</h4>
                </div>
              </div>
            </div>
            <div className="col-12 testadds mt-4">
                    <div
                      id="gpt-passback-gamerji.com.Banner0.1732793637"
                      style={{
                        width: '100%',
                        height: 'auto',
                        // position: "fixed",
                        bottom: '0',
                        zIndex: '9999',
                        // background: "red",
                      }}
                    >
                      {/* The ad slot will be displayed here */}
                    </div>
                    </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QualificationModal;
