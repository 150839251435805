import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { howToJoinGame } from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import { toast } from "react-toastify";
import { logout } from "../../../Utilities/logout";
import { useTranslation } from "react-i18next";
import Loader from "../../Common/Loader";

const HowToJoin = ({ isHowToJoinOpen, setIsHowToJoinOpen }) => {
  const [show, setShow] = useState(isHowToJoinOpen);
  const [contentPairs, setContentPairs] = useState([]);
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false)

  const fetchDetails = async () => {
    setLoader(true);
    const body = {
      game: localStorage.getItem("activeGameId")
    }
    try {
      const res = await howToJoinGame(body); // Pass the filter object as an argument
      const dataList = res?.data?.item?.content || []; // Access the content array
      const pairs = [];
      let textContent = null,
        imageContest = null;

      dataList?.forEach((item, index) => {
        const type = item?.type;
        const content = item?.content;

        if (type === "text") {
          textContent = content;
          pairs.push({ text: textContent });
        } else if (type === "image") {
          imageContest = content;
          pairs.push({ image: imageContest });
        }
      });

      // Update state with the pairs
      setContentPairs(pairs);
      setLoader(false);
    } catch (error) {
      // Handle any errors that may occur during the API request
      if (
        error?.response?.data?.errors?.[0]?.code ==
        "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
        "middlewares.token_parser.validation.token_expired"
      )
        logout();
      toast.error(error.response?.data?.errors[0]?.msg);
      setLoader(false);
    }
  };

  const handleClose = () => {
    setIsHowToJoinOpen((prev) => false);
    setShow(false);
  };
  useEffect(() => {
    fetchDetails();
  }, []);
  useEffect(() => {
    // Load the GPT library
    const gptScript = document.createElement('script');
    gptScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'; 
    gptScript.async = true;
    document.body.appendChild(gptScript);
  
    // Initialize the ad slot after the script loads
    gptScript.onload = () => {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.cmd.push(() => {
        window.googletag.defineSlot(
          '/23081990290,23206251223/gamerji.com.Banner0.1732793637', 
          [[728,90],"fluid"],
          'gpt-passback-gamerji.com.Banner0.1732793637'
        ).addService(window.googletag.pubads());
  
        window.googletag.enableServices();
        window.googletag.display('gpt-passback-gamerji.com.Banner0.1732793637');
        
        // // Set state to true once the ad is displayed
      // setIsAdLoaded(true);
      });
    };
  
    // Clean up the script tag on component unmount
    return () => {
      document.body.removeChild(gptScript);
    };
  }, []);
  func("location", window.location.pathname.split('/'));
  return (
    <>
      {loader ? <Loader /> :
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          className="winningBreakupModal modal fade"
          centered
        >
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-sm-9 col-12">
                <div className="tab-content auth-content">
                  <form className="row justify-content-center text-dark">
                    <h2 className="verification_heading text-center">
                      {t("contest.how_to_join")}
                    </h2>

                    <div className="grid-container pt-4">
                      {contentPairs.length > 0 ? contentPairs.map((pair, index) => (
                        pair.text ?
                          <p className="fs-6 mt-3">
                            {pair.text}
                          </p>

                          :
                          <div
                            className="mt-3"
                            style={{
                              height: "15rem",
                            }}
                          >
                            <img
                              src={pair.image}
                              className="rounded10 w-100 h-100"
                              alt=""
                            />
                          </div>
                      )) : (
                        <h6 className="mt-2 text-center text-dark">{t('notFound.no_records_found')}</h6>
                      )}

                    </div>
                    <div className="col-12 testadds mt-4">
                  <div
                    id="gpt-passback-gamerji.com.Banner0.1732793637"
                    style={{
                      width: '100%',
                      height: 'auto',
                      // position: "fixed",
                      bottom: '0',
                      zIndex: '9999',
                      // background: "red",
                    }}
                  >
                    {/* The ad slot will be displayed here */}
                  </div>
                </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  );
};

export default HowToJoin;
