import React, { useEffect, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { getHeaderApi } from "../../../Utilities/controller";
import "../../../assets/css/PaymentStatus.css";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import getSymbolFromCurrency from "currency-symbol-map";
import { useProfileDataContext } from "../ProfileContext";
import {logout} from "../../../Utilities/logout"
import moment from "moment";

const EaseBuzzPaymentStatus = (props) => {
  const navigate = useNavigate();

  let { id } = useParams();
  const { t } = useTranslation();
  const { profileData, updateProfileData } = useProfileDataContext();
  const [searchParams] = useSearchParams();
  const [transactionData, setTansactionData] = useState({});
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [user, setUser] = useState(null)
  const [verified, setVerified] = useState(false)

  const handleClose = () => {
    setShow(false);
    navigate("/home");
  };

  const handlerDone = async () => {
    const resProfile = await getHeaderApi();
    updateProfileData(resProfile?.data);
    setShow(false);
    // navigate("/home/coin-store");
    window.location.href = "/home"
  };

  const fetchPayUDetails = async () => {
    try {
      const paymentStatus = searchParams.get("status");
      const transactionId = searchParams.get("txnid");
      const user = searchParams.get("user");
      setUser(user)
      const message = searchParams.get("message");
      const amount = parseInt(searchParams.get("amount")) || undefined;
      const paymentTimestamp = searchParams.get("paymentTimestamp");
      let payload = {
        paymentStatus,
        transactionId,
        message,
        amount,
        paymentTimestamp,
      };
      setTansactionData(payload);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {

    let profileLite1 = localStorage.getItem('profile')
    profileLite1 = JSON.parse(profileLite1)
    // console.log("profileLite1",profileLite1 , typeof profileLite1?.item?.user_id ,user, profileLite1?.item?.user_id.toString() === user?.toString())
    if(profileLite1 && user != null){
    if (profileLite1 && profileLite1?.item?.user_id && profileLite1?.item?.user_id.toString() === user) {
      setVerified(true)
    }
    else {
      console.log("LOG-OUT" )
      logout();
    }
  }

  }, [user])

  useEffect(() => {
    fetchPayUDetails();
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="content text-center">
          <div class="row justify-content-center">
            <div class="col-12">
              { verified && 
              <div className="pg_status_main_component">
                {/* <ToastContainer /> */}
                {transactionData?.paymentStatus === "SUCCESS" ?
                  <div className="pg_status_body">
                    <div className="pg_ps_status_component">
                      {/* BLock */}
                      <div
                        className={"pg_status_background_success"}
                      >
                        {/* Title */}
                        <div className="pg_status_title">
                          {"Success!"}
                        </div>
                        {/* Currency */}
                        {!loading && (
                          <>
                            {(transactionData?.amount && typeof transactionData.amount === 'number') &&
                              <div className="pg_status_amount">
                                {getSymbolFromCurrency("INR")}{" "}
                                {transactionData?.amount}
                              </div>
                            }
                            {(transactionData?.paymentTimestamp && transactionData?.paymentTimestamp !== undefined && transactionData?.paymentTimestamp !== 'undefined') &&
                              <div className="pg_status_date">
                                {moment(new Date(transactionData?.paymentTimestamp)).subtract(5, 'hours').subtract(30, 'minutes').format("DD/MM/YYYY, hh:mm a")}
                              </div>
                            }
                            {(transactionData?.transactionId && transactionData?.transactionId !== undefined && transactionData?.transactionId !== 'undefined') &&
                              <div className="pg_status_transaction">
                                {t("premium.transaction_id")} :{" "}
                                {transactionData?.transactionId}
                              </div>
                            }
                          </>
                        )}
                        {/* {message &&
                      <div className="pg_status_transaction">{message}</div>
                      } */}
                        <div className="pg_status_transaction">
                          {transactionData?.message}
                        </div>
                      </div>
                      <button
                        className={`btn btn-primary main-btn next mt-4 mb-4 ml-2`}
                        style={{ width: "100%", border: "1px solid" }}
                        onClick={handlerDone}
                      >
                        {t("premium.done")}
                      </button>
                    </div>
                  </div> : (transactionData?.paymentStatus === "FAILED" ||
                    transactionData?.paymentStatus === "FAILURE") ?

                    <div className="pg_status_body">
                      <div className="pg_ps_status_component">
                        {/* BLock */}
                        <div
                          className={"pg_status_background_failed"}
                        >
                          {/* Title */}
                          <div className="pg_status_title">
                            {"Failed!"}
                          </div>
                          {/* Currency */}
                          {!loading && (
                            <>
                              {(transactionData?.amount && typeof transactionData.amount === 'number') &&
                                <div className="pg_status_amount">
                                  {getSymbolFromCurrency("INR")}{" "}
                                  {transactionData?.amount}
                                </div>
                              }
                              {(transactionData?.paymentTimestamp && transactionData?.paymentTimestamp !== undefined && transactionData?.paymentTimestamp !== 'undefined') &&
                                <div className="pg_status_date">
                                  {moment(new Date(transactionData?.paymentTimestamp)).subtract(5, 'hours').subtract(30, 'minutes').format("DD/MM/YYYY, hh:mm a")}
                                </div>
                              }
                              {(transactionData?.transactionId && transactionData?.transactionId !== undefined && transactionData?.transactionId !== 'undefined') &&
                                <div className="pg_status_transaction">
                                  {t("premium.transaction_id")} :{" "}
                                  {transactionData?.transactionId}
                                </div>
                              }
                            </>
                          )}
                          {/* {message && */}
                          {/* <div className="pg_status_transaction">{message}</div> */}
                          {/* } */}
                          <div className="pg_status_transaction">
                            {t("premium.cant_proceed")}
                          </div>
                        </div>
                        <button
                          className={`btn btn-primary main-btn next mt-4 mb-4 ml-2`}
                          style={{ width: "100%", border: "1px solid" }}
                          onClick={handlerDone}
                        >
                          {t("premium.done")}
                        </button>
                      </div>
                    </div>
                    :

                    <div className="pg_status_body">
                      <div className="pg_ps_status_component">
                        {/* BLock */}
                        <div
                          className={"pg_status_background_pending"}
                        >
                          {/* Title */}
                          <div className="pg_status_title">
                            {"Pending..."}
                          </div>
                          {/* Currency */}
                          {!loading && (
                            <>
                              {(transactionData?.amount && typeof transactionData.amount === 'number') &&
                                <div className="pg_status_amount">
                                  {getSymbolFromCurrency("INR")}{" "}
                                  {transactionData?.amount}
                                </div>
                              }

                              {(transactionData?.paymentTimestamp && transactionData?.paymentTimestamp !== undefined && transactionData?.paymentTimestamp !== 'undefined') &&
                                <div className="pg_status_date">
                               {moment(new Date(transactionData?.paymentTimestamp)).subtract(5, 'hours').subtract(30, 'minutes').format("DD/MM/YYYY, hh:mm a")}
                                </div>
                              }
                              {(transactionData?.transactionId && transactionData?.transactionId !== undefined && transactionData?.transactionId !== 'undefined') &&
                                <div className="pg_status_transaction">
                                  {t("premium.transaction_id")} :{" "}
                                  {transactionData?.transactionId}
                                </div>
                              }
                            </>
                          )}
                          {message &&
                            <div className="pg_status_transaction">{message}</div>
                          }
                          <div className="pg_status_transaction">
                            {t("premium.transaction_inprogess")}
                          </div>
                        </div>
                        <button
                          className={`btn btn-primary main-btn next mt-4 mb-4 ml-2`}
                          style={{ width: "100%", border: "1px solid" }}
                          onClick={handlerDone}
                        >
                          {t("premium.done")}
                        </button>
                      </div>
                    </div>

                }


                {/* <div className="pg_status_body">
                  <div className="pg_ps_status_component">
              
                    <div
                      className={
                        transactionData?.paymentStatus === "SUCCESS"
                          ? "pg_status_background_success"
                          : transactionData?.paymentStatus === "FAILED" ||
                            transactionData?.paymentStatus === "FAILURE"
                            ? "pg_status_background_failed"
                            : transactionData?.paymentStatus === "DECLINED" ||
                              transactionData?.paymentStatus === "CANCELLED"
                              ? "pg_status_background_failed"
                              : "pg_status_background_pending"
                      }
                    >
                     
                      <div className="pg_status_title">
                        {transactionData?.paymentStatus === "SUCCESS"
                          ? "Success!"
                          : transactionData?.paymentStatus === "FAILED" ||
                            transactionData?.paymentStatus === "FAILURE"
                            ? "Failure!"
                            : transactionData?.paymentStatus === "DECLINED" ||
                              transactionData?.paymentStatus === "CANCELLED"
                              ? "User Cancelled"
                              : "Processing..."}
                      </div>
                     
                      {!loading && (
                        <>
                          <div className="pg_status_amount">
                            {getSymbolFromCurrency("INR")}{" "}
                            {transactionData?.amount}
                          </div>

                          <div className="pg_status_date">
                            {new Date(
                              transactionData?.paymentTimestamp
                            ).toLocaleString()}
                          </div>

                          <div className="pg_status_transaction">
                            {t("premium.transaction_id")} :{" "}
                            {transactionData?.transactionId}
                          </div>
                        </>
                      )}

                      {transactionData?.paymentStatus === "SUCCESS" ? (
                        <div className="pg_status_transaction">{message}</div>
                      ) : transactionData?.paymentStatus === "FAILED" ||
                        transactionData?.paymentStatus === "FAILURE" ? (
                        <div className="pg_status_transaction">
                          {t("premium.cant_proceed")}
                        </div>
                      ) : (
                        t("premium.transaction_inprogess")
                      )}
                      <div className="pg_status_transaction">
                        {transactionData?.message}
                      </div>
                    </div>
                    <button
                      className={`btn btn-primary main-btn next mt-4 mb-4 ml-2 ${transactionData?.paymentStatus !== "SUCCESS" &&
                          (transactionData?.paymentStatus === "FAILED" ||
                            transactionData?.paymentStatus === "FAILURE")
                          ? "disabled"
                          : ""
                        }`}
                      style={{ width: "100%", border: "1px solid" }}
                      onClick={handlerDone}
                    >
                      {t("premium.done")}
                    </button>
                  </div>
                </div> */}
              </div>
              }
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default EaseBuzzPaymentStatus;
