import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { func } from "../../../Utilities/logFunc";
import ContestList from "./ContestList";
import TournamentList from "./TournamentList";
import Private from "./Private";
import { useTranslation } from "react-i18next";

import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";

const MyContestTab = () => {
  const {t} = useTranslation();
  const [active, setActive] = useState(0)
  useEffect(() => {
    // Load the GPT library
    const gptScript = document.createElement('script');
    gptScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'; 
    gptScript.async = true;
    document.body.appendChild(gptScript);

    // Initialize the ad slot after the script loads
    gptScript.onload = () => {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.cmd.push(() => {
        window.googletag.defineSlot(
          '/23081990290,23206251223/gamerji.com.Banner0.1731410377', 
          [[970,90],[970,250],"fluid"],
          'gpt-passback-gamerji.com.Banner0.1731410377'
        ).addService(window.googletag.pubads());

        window.googletag.enableServices();
        window.googletag.display('gpt-passback-gamerji.com.Banner0.1731410377');
        
        // // Set state to true once the ad is displayed
      // setIsAdLoaded(true);
      });
    };

    // Clean up the script tag on component unmount
    return () => {
      document.body.removeChild(gptScript);
    };
  }, []);
  func("location", window.location.pathname.split('/'));
  return (
    <div class="col-12 commonTab contestTab">
      <ul class="nav nav-pills  d-flex justify-content-between auth-nav" id="contestTab" role="tablist">
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link active"
            id="contest-tab"
            data-bs-toggle="tab"
            data-bs-target="#contest-tab-pane"
            type="button"
            role="tab"
            aria-controls="contest-tab-pane"
            aria-selected="true"
            style={{width:'100%'}}
            onClick={()=>setActive(0)}
            
          >
            {t("myContest.contest")}
          </button>
        </li>
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link"
            id="tournaments-tab"
            data-bs-toggle="tab"
            data-bs-target="#tournaments-tab-pane"
            type="button"
            role="tab"
            aria-controls="tournaments-tab-pane"
            aria-selected="false"
            style={{width:'100%'}}
            onClick={()=>setActive(1)}

          >
            {t("myContest.tournament")}
          </button>
        </li>
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link"
            id="private-tab"
            data-bs-toggle="tab"
            data-bs-target="#private-tab-pane"
            type="button"
            role="tab"
            aria-controls="private-tab-pane"
            aria-selected="false"
            style={{width:'100%'}}
            onClick={()=>setActive(2)}

          >
            {t("myContest.private")}
          </button>
        </li>
      </ul>
      <div className="d-flex justify-content-center align-content-center">
        <div
          id="gpt-passback-gamerji.com.Banner0.1731410377"
          style={{
            width: '70%',
            height: 'auto',
            position: "fixed",
            bottom: '0',
            zIndex: '10',
            // background: "red",
          }}
        >
        </div>
        </div>
      <div class="tab-content pt-4" id="contestTabContent">
      <div
        class="tab-pane fade show active"
        id="contest-tab-pane"
        role="tabpanel"
        aria-labelledby="contest-tab"
        tabindex="0"
      
      // style={{paddingBottom:'40px'}}

      >
        {
        active == 0 &&  <ContestList />
      }
     
         
        </div>
        <div
      class="tab-pane fade"
      id="tournaments-tab-pane"
      role="tabpanel"
      aria-labelledby="tournaments-tab"
      tabindex="0"
      style={{paddingBottom:'40px'}}
     


    >
      {/* {console.log(active)} */}
      {
        
        active == 1 &&  <TournamentList />
      }
     
      
      </div>
         <div
      class="tab-pane fade"
      id="private-tab-pane"
      role="tabpanel"
      aria-labelledby="private-tab"
      tabindex="0"
    

      // style={{ paddingBottom: "40px" }}
    >
      {
        active == 2 &&   <Private />
      }
     </div>
        
      </div>
    </div>
  );
};

export default MyContestTab;
