import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { addUserInGameName, getUserInGameName } from '../../../Utilities/controller';
import { toast } from 'react-toastify';
import { errors } from '../../../Utilities/error';
import { func } from '../../../Utilities/logFunc';
import { useProfileDataContext } from '../ProfileContext';
import { useTranslation } from "react-i18next";
import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";
const AddInNameGameModal = ({ isBaseAuthOpen, setIsBaseAuthOpen }) => {
  const [show, setShow] = useState(isBaseAuthOpen);
  const [setNoGameFound, setNoGameNameFound] = useState(false);
  const [UserGameNameData, setUserGameNameData] = useState([]);
  const [userINGameName, setuserINGameName] = useState();
  const [newName, setnewtName] = useState("");
  const { profileData, updateProfileData } = useProfileDataContext()
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const handleClose = () => {
    setIsBaseAuthOpen((prev) => false);
    setShow(false);
  };
  const onSubmit = async () => {
    if (newName !== "") {
      try {
        let payload = {
          user: profileData?.item?.user_id,
          game: localStorage.getItem("activeGameId"),
          userINGameName: newName,
        };

        let res = await addUserInGameName(payload);


        eventTracking(events.gamenamebox, {
          GJ_GameName: newName,
          GJ_GameID: localStorage.getItem("activeGameId")
        });
        eventTracking(events.change_username, {
          GJ_GameName: localStorage.getItem('activeGame'),
          GJ_GameID: localStorage.getItem('activeGameId'),
          GJ_AddIGN: newName
        });
        eventTracking(events.change_username_AddUpdate, {
          GJ_GameName: localStorage.getItem('activeGame'),
          GJ_GameID: localStorage.getItem('activeGameId'),
          GJ_CurrentIGN: userINGameName,
          GJ_NewIGN: newName
        });
        if (res?.data?.success) {
          // GetInGameNames();
          // setcurrentName(newName);
          // setShowContacts(false);
          // setIsBaseAuthOpen(false);
          // setnewtName("");
          // toast.success(res?.data?.data?.[0]?.msg);
          toast.success(t("success_Game_Username_updated_successfully"))
          handleClose()
        } else {
          // Handle the case when the response status is not 200 (e.g., an error occurred)
          console.error("API request failed with status:", res.status);
          // Handle the error as needed (e.g., show an error message)
          // Example: toast.error("An error occurred while updating the username");

        }
      } catch (error) {
        // Handle any exceptions that occur during the API request
        console.error("An error occurred during the API request:", error);
        // Handle the error as needed (e.g., show an error message)
        // Example: toast.error("An error occurred while updating the username");
        toast.error(error?.response?.data?.data?.[0]?.msg)
      }
    } else {
      setError(t('error_enter_game_name'))
      //     toast.error(errors.success_Please_enter_Gamerji_username, {
      //     //   position: "top-left",
      //       autoClose: 2000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //     });
    }
  }
  const getInGameNames = async () => {
    try {

      let payload = {
        search: {
          user: profileData?.item?.user_id,
          game: localStorage.getItem("activeGameId"),
        },
      };

      let userInGameNameResponse = await getUserInGameName(payload);

      if (userInGameNameResponse?.data?.success) {
        func("username", userInGameNameResponse?.data?.data)
        func("active game", localStorage.getItem("activeGameId"))

        const activeGame = localStorage.getItem("activeGameId")
        let index = null
        userInGameNameResponse?.data?.data.filter((ele, ind) => {
          if (ele?.game === activeGame)
            index = ind
        })
        func("index", index)
        setuserINGameName(userInGameNameResponse?.data?.data[0]?.userINGameName)
        // setUserGameNameData(userInGameNameResponse?.data?.data);
        // localStorage.setItem(
        //   "userInGameNames",
        //   JSON.stringify(userInGameNameResponse.data)
        // );
        setNoGameNameFound(false);

      } else {
        setNoGameNameFound(true);
      }
    } catch (error) {
      setNoGameNameFound(true);
    }
  };
  useEffect(() => {
    // const activeGameId = localStorage.getItem("activeGameId"); // Replace with the actual value

    // // Retrieve the JSON response from localStorage
    // const localStorageData = localStorage.getItem("userInGameNames");

    // // Check if localStorageData is not null or undefined
    // if (localStorageData) {
    //   // Parse the JSON data
    //   const parsedData = JSON.parse(localStorageData);

    //   // Iterate through the data array and find matching records
    //   parsedData?.data?.forEach((record) => {
    //     if (record.game === activeGameId) {
    //       console.log(record?.userINGameName);
    //       setuserINGameName(record?.userINGameName);
    //     }
    //   });
    // }   
    getInGameNames()
  }, [])
  useEffect(() => {
    // Load the GPT library
    const gptScript = document.createElement('script');
    gptScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'; 
    gptScript.async = true;
    document.body.appendChild(gptScript);
  
    // Initialize the ad slot after the script loads
    gptScript.onload = () => {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.cmd.push(() => {
        window.googletag.defineSlot(
          '/23081990290,23206251223/gamerji.com.Banner0.1732793637', 
          [[728,90],"fluid"],
          'gpt-passback-gamerji.com.Banner0.1732793637'
        ).addService(window.googletag.pubads());
  
        window.googletag.enableServices();
        window.googletag.display('gpt-passback-gamerji.com.Banner0.1732793637');
        
        // // Set state to true once the ad is displayed
      // setIsAdLoaded(true);
      });
    };
  
    // Clean up the script tag on component unmount
    return () => {
      document.body.removeChild(gptScript);
    };
  }, []);
  func("location", window.location.pathname.split('/'));
  return (
    <Modal
      show={isBaseAuthOpen}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      {/* <Modal.Header closeButton>
      <Modal.Title>Modal title</Modal.Title>
    </Modal.Header> */}
      <Modal.Body >
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div className="row justify-content-center">
          <div className="col-sm-12 mb-4 align-items-center text-center">
            <h3 className="verification_heading bodyBoldFont text-center text-dark">
              {t('tournaments.add_change_game', { game_name: localStorage.getItem("activeGame") })}
            </h3>
          </div>
          <div className="col-xl-6 col-lg-7 col-sm-9 col-12">
            <div className="tab-content auth-content">
              <div className="row justify-content-center">
                <div className="custom-container">
                  {!setNoGameFound && (
                    <div className="mb-4">
                      <h4
                        className="text-nowrap h6 text-dark"
                      // style={{
                      //   width: "191px",
                      //   height: "20px",
                      //   top: "410px",
                      //   left: "727px",

                      //   fontSize: "16px",
                      //   fontWeight: 400,
                      //   lineHeight: "20px",
                      //   letterSpacing: "0.10000000149011612px",
                      //   textAlign: "left",
                      //   marginLeft: "20px",
                      // }}
                      >
                        {t('tournaments.current_active_name', { activeGame: localStorage.getItem("activeGame") })}
                      </h4>

                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="verificationcode"
                        placeholder={t('tournaments.enter_current_active_name', { activeGame: localStorage.getItem("activeGame") })}
                        // style={{
                        //   width: "100%",
                        //   height: "45px",
                        //   borderRadius: "100px",
                        //   padding: "10px",
                        //   marginBottom: "35px",
                        //   marginLeft: "20px",
                        // }}
                        value={userINGameName}
                      />
                    </div>
                  )}
                  <div className="mb-4">
                    <h4
                      className="text-nowrap h6 text-dark"
                    // style={{
                    //   width: "191px",
                    //   height: "20px",
                    //   top: "410px",
                    //   left: "727px",

                    //   fontSize: "16px",
                    //   fontWeight: 400,
                    //   lineHeight: "20px",
                    //   letterSpacing: "0.10000000149011612px",
                    //   textAlign: "left",
                    //   marginLeft: "20px",
                    // }}
                    >
                      {t('tournaments.new_username', { activeGame: localStorage.getItem("activeGame") })}
                    </h4>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="inputCouponCode"
                      placeholder={t('tournaments.enter_new_active_name', { activeGame: localStorage.getItem("activeGame") })}
                      // style={{
                      //   width: "100%",
                      //   height: "45px",
                      //   borderRadius: "100px",
                      //   padding: "10px",
                      //   marginLeft: "20px",
                      // }}
                      value={newName}
                      maxLength={32}
                      onChange={(e) => setnewtName(e.target.value)}
                    />
                  </div>
                </div>


                <div className="d-grid mt-2">
                  <button
                    className="btn btn-primary btn-arrow mb-3"

                    onClick={onSubmit}
                  // style={{ marginLeft: "20px" }}
                  >
                    {t('contest.submit')}
                  </button>
                  {
                    error && <div className='error text-center'>{error}</div>
                  }
                  <div className="links" />
                </div>
                <div className="col-12 testadds mt-4">
                  <div
                    id="gpt-passback-gamerji.com.Banner0.1732793637"
                    style={{
                      width: '100%',
                      height: 'auto',
                      // position: "fixed",
                      bottom: '0',
                      zIndex: '9999',
                      // background: "red",
                    }}
                  >
                    {/* The ad slot will be displayed here */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

    </Modal>

  )
}

export default AddInNameGameModal