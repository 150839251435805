import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { func } from "../../../Utilities/logFunc";
import moment from "moment";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import arrow from "../../../assets/new-images/right-arrow-black.svg"

import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";
import { useProfileDataContext } from "../ProfileContext";

const TournamentModal = ({
  showTournamentModal,
  setShowTournamentModal,
  setShowTournamentConfirmationModal,
  setMatch,
  tournaments,
  setShowJVC,
  userInGameName
}) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(showTournamentModal);
  const [error, setError] = useState(t("tournaments.select_match_to_check_next_round_timing"));
  const [finaleDate, setFinaleDate] = useState();
  const [rounds, setRounds] = useState([]);
  const [activeId, setActiveId] = useState(null);
  // let premiumUser = JSON.parse(localStorage.getItem("premiumUser"))
  const {premiumUser} = useProfileDataContext
  const handleClose = () => {
    func(setShowTournamentModal);
    setShowTournamentModal((prev) => false);
    setShow(false);
    if (setShowJVC) setShowJVC(false);
  };

  const nextHandler = () => {
    if (error) {
      return;
    }
    eventTracking(events.Next, {
      GJ_GameName: tournaments?.gameAry?.[0]?.name, 
      GJ_GameID: tournaments?.gameAry?.[0]?._id,
      GJ_TournamentID: tournaments?._id, 
      GJ_ContestID: activeId,
      GJ_TournamentDate: tournaments?.startDate,
       GJ_TournamentTime: tournaments?.startDate
    });
    setShowTournamentModal((prev) => false);
    setShowTournamentConfirmationModal((prev) => true);
  };

  const ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  };
  const onSelectTime = (item, e) => {
    eventTracking(events.TOURNAMENT_TIMING_SELECTION, {
      GJ_GameName: tournaments?.gameAry?.[0]?.name, GJ_GameID: tournaments?.gameAry?.[0]?._id,
      GJ_TournamentID: tournaments?._id, GJ_ContestID: item?._id,
      GJ_TournamentDate: tournaments?.startDate, GJ_TournamentTime: tournaments?.startDate
    });
    setError("");
    setMatch(item);
    let temp = [];
    var round = item.code;
    tournaments.rounds?.map((item1, index) => {
      if (index !== 0) {
        item1?.matches?.map((item2, index) => {
          if (item2.contest.findIndex((val) => val === round) !== -1) {
            round = item2.matchId;
            temp.push({
              round: item1.name,
              date: item2.date,
              time: item2.time,
            });
          }
        });
      }
    });
    // console.log(round)
    setRounds((prev) => [...temp]);
  };
  const [timelist, settimeList] = useState([]);
  const [condata, setcondata] = useState([]);

  // const setTournamnetTime = async () => {

  //   let ttm = [];
  //   let temptime2 = [];
  //   // console.log(props?.allFeaturedData?.contestsList
  //   //   )
  //   await Promise.all(
  //     props?.allFeaturedData?.contestsList?.map(async (item1, i) => {
  //       // console.log(">>>",item1)
  //       var tempContest = item1;
  //       for (let index = 0; index < props?.allFeaturedData?.roundDatetime.length; index++) {
  //         const item = props?.allFeaturedData?.roundDatetime[index];
  //         // console.log("<<<",item)
  //         var today = new Date();
  //         var cdate = moment(item?.date).format("y-MM-DD");
  //         var ctime = moment(item?.time).format("HH:mm");
  //         var contestDateTime = moment(cdate + " " + ctime);
  //         var contestdatetimestring = cdate + " " + ctime;
  //         // console.log("****",i)
  //         if ((i=0 && item1.totalJoinedPlayers===0)) {
  //           temptime2.push(contestdatetimestring);
  //         } else {
  //           var diff = temptime2.includes(contestdatetimestring);
  //         }
  //        // console.log("@", tempContest.code, item.matchId, index, "CONDITION::3:", (item.matchId === tempContest.code), "CONDITION::2:", (index === 0 || !diff), "CONDITION::1:", (today < new Date(contestDateTime)))
  //         if (item.matchId === tempContest.code) {
  //           if (index === 0 || !diff) {
  //             if (today < new Date(contestDateTime)) {
  //               ttm.push(item);
  //               temptime2.push(contestdatetimestring);
  //             }
  //           }
  //         }
  //       }
  //     })
  //   );
  //   setTimeout(() => {
  //     console.log("ttm-final", ttm, temptime2);
  //     setTimings(ttm);
  //   }, 1000);
  // };


  useEffect(() => {
    let timeList=[]
    let cdata=[]
   Promise.all(tournaments?.rounds?.map((item, index) => {
      if(item?.isFinalRound == "no" && index === 0){
        item?.matches?.map((item2, ind) => {
          tournaments?.contestsList?.map((contestData, ind) => {
            // console.log(">>",contestData?.date , contestData?.time, (moment(contestData?.date).format("y-MM-DD") + " " + moment(contestData?.time).format("HH:mm")) , new Date())
            var today = new Date();
                    var cdate = moment(contestData?.date).format("y-MM-DD");
                    var ctime = moment(contestData?.time).format("HH:mm");
                    console.log(cdata, ctime)
                    var contestDateTime = moment(cdate + " " + ctime);
                    var contestdatetimestring = cdate + " " + ctime;
                    // console.log("Moadal>>>>>>>>>>>>",today > new Date(contestDateTime), new Date(contestDateTime), today)
            if(contestData?.code === item2?.matchId ){
              
            if(timeList&& timeList.length>0){
             if(!timeList.includes((moment(contestData?.date).format("y-MM-DD") + " " + moment(contestData?.time).format("HH:mm")))){
              cdata.push({code:contestData.code,time:moment(contestData?.date).format("y-MM-DD") + " " + moment(contestData?.time).format("HH:mm")})
              timeList.push(moment(contestData?.date).format("y-MM-DD") + " " + moment(contestData?.time).format("HH:mm"))
             }
            }else{
              cdata.push({code:contestData.code,time:moment(contestData?.date).format("y-MM-DD") + " " + moment(contestData?.time).format("HH:mm")})
              timeList.push(moment(contestData?.date).format("y-MM-DD") + " " + moment(contestData?.time).format("HH:mm"))
             }
            } 
        })
      })
    }
  }))
    settimeList(timeList)
    setcondata(cdata)
    
  }, [tournaments]);

  useEffect(() => {
    var finale_index = tournaments?.rounds?.findIndex(
      (val) => val?.isFinalRound?.toLowerCase() === "yes"
    );
    // console.log("finale_index: ", finale_index);
    setFinaleDate(
      finale_index !== -1
        ? tournaments?.rounds?.[finale_index]?.matches?.[0]
        : []
    );
  }, [rounds, error]);
  useEffect(() => {
    // Load the GPT library
    const gptScript = document.createElement('script');
    gptScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'; 
    gptScript.async = true;
    document.body.appendChild(gptScript);

    // Initialize the ad slot after the script loads
    gptScript.onload = () => {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.cmd.push(() => {
        window.googletag.defineSlot(
          '/23081990290,23206251223/gamerji.com.Banner0.1732793637', 
          [[728,90],"fluid"],
          'gpt-passback-gamerji.com.Banner0.1732793637'
        ).addService(window.googletag.pubads());

        window.googletag.enableServices();
        window.googletag.display('gpt-passback-gamerji.com.Banner0.1732793637');
        
        // // Set state to true once the ad is displayed
      // setIsAdLoaded(true);
      });
    };

    // Clean up the script tag on component unmount
    return () => {
      document.body.removeChild(gptScript);
    };
  }, []);
  func("location", window.location.pathname.split('/'));
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className={premiumUser ? "premiumUserTournamentModal tournamentModal modal fade" : "tournamentModal modal fade "}
      centered
    >
      <Modal.Body>
        <button
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="row justify-content-center">
          <div class="col-lg-11 col-12 px-lg-4">
            <div class="title">
              <h3 class={`text-center bodyBoldFont mb-4 pb-3 ${premiumUser ? "text-white" : "text-dark"}`}>
                {tournaments?.title}
              </h3>
            </div>
            <div class="text-center">
              <h4 class="mb-3">
                {tournaments?.gameAry?.[0]?.name} -{" "}
                {tournaments?.gameTypeAry?.name}
              </h4>
              <h4 class="text-primary-color-red mb-3">
                {moment(tournaments?.startDate).format("DD MMMM Y")} {t("tournaments.to")}{" "}
                {moment(tournaments?.endDate).format("DD MMMM Y")}
              </h4>

              {tournaments?.rounds?.map((item, index) => (
                <>
                  {item?.isFinalRound == "no" && index === 0 ? (
                    <>
                      <div class="d11-round-text py-3" key={index}>
                        <h4 class={`mb-0 ${premiumUser ? "" : "text-white"}`}>
                          {t("tournaments.select_1st_round_time", {
                            n: `${ordinal_suffix_of(index + 1)}`,
                          })}
                        </h4>
                      </div>

                      <div class="d-flex flex-column text-start mt-4 pt-2">
                        {item?.matches?.map((item2, ind) => (
                          <>
                            {tournaments?.contestsList?.map(
                              (contestData, ind) => (
                                <>
                                  {contestData?.code === item2?.matchId && condata.some(item => item.code ===item2?.matchId)&& (
                                    <div class="form-check text-dark" key={ind}>
                                      {/* {console.log(item2?.time)} */}
                                      <input
                                        class="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id={`flexRadioDefault${ind}`}
                                        disabled={false}
                                        value={contestData?._id}
                                        checked={activeId == contestData?._id}
                                        onChange={(e) => {
                                          func("tou Modal 155", contestData);
                                          func("tou Modal 156", e.target.value);
                                          setActiveId(contestData?._id);
                                          onSelectTime(contestData, e);
                                        }}
                                      />
                                      <label
                                        class={`form-check-label ${premiumUser ? "text-white" : ""}`}
                                        for={`flexRadioDefault${ind}`}
                                      >
                                        {moment(item2?.date).format(
                                          "DD MMMM Y"
                                        )}
                                        ,{" "}
                                        {moment(item2?.time).format("hh:mm A")}
                                      </label>
                                    </div>
                                  )}
                                </>
                              )
                            )}
                          </>
                        ))}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>


              ))}

              {error ? (
                <>
                  <h4 class={`d11-final-text ${premiumUser ? "" : "color-primary-color-red"} `}>{error}</h4>
                  <h4 class="bodyBoldFont pt-3 mb-0">
                    {t('tournaments.finale_will_be_scheduled_at', { date: `${moment(finaleDate?.date).format("DD MMMM Y")} ${moment(finaleDate?.time).format("hh:mm A")}` })}
                    {/* Finale will be scheduled at{" "}
                    {moment(finaleDate?.date).format("DD MMMM Y")},{" "}
                    {moment(finaleDate?.time).format("hh:mm A")} */}
                  </h4>
                </>
              ) : tournaments?.rounds?.length > 2 ? (
                <>
                  <OwlCarousel
                    className="owl-carousel owl-theme"
                    autoWidth={false}
                    items={1}
                    loop
                    margin={0}
                    // stagePadding={50}
                    autoplay={false}
                    dots={false}
                    nav={true}
                  // navText={[
                  //   <i className="fa fa-arrow-left" aria-hidden="true">hhh</i>,
                  //   <i className="fa fa-arrow-right" aria-hidden="true"></i>,
                  // ]}
                  >
                    {func("time OwlCarousel", rounds)}
                    {rounds &&
                      rounds?.map((item, index) => {
                        return (
                          <div>
                            <h4 class="bodyBoldFont text-uppercase mt-2">
                              {item?.round}
                            </h4>
                            <h4 class="bodyBoldFont pt-3 mb-0">
                              {moment(item?.date).format("DD MMMM Y")},{" "}
                              {moment(item?.time).format("hh:mm A")}
                            </h4>
                          </div>
                        );
                      })}
                  </OwlCarousel>
                </>
              ) : (
                <>
                  <h4 class="bodyBoldFont text-uppercase mt-2">{t("tournaments.final")}</h4>
                  <h4 class="bodyBoldFont pt-3 mb-0">
                    {moment(finaleDate?.date).format("DD MMMM Y")},{" "}
                    {moment(finaleDate?.time).format("hh:mm A")}
                  </h4>
                </>
              )}

              {
                // item?.isFinalRound == "yes" ?
                // (
                //   <>
                //     {item?.matches?.map((item2) => (
                //       <>
                //         <h4 class="d11-final-text">
                //         {
                //           error && error
                //         }
                //         </h4>
                //         <h4 class="bodyBoldFont pt-3 mb-0">
                //         Finale will be scheduled at  {moment(item2?.date).format("DD MMMM Y")},{" "} {moment(item2?.time).format("hh:mm A")}
                //         </h4>
                //       </>
                //     ))}
                //   </>
                // ) : ""}
              }

              <div class="row justify-content-center mt-4 pt-2">
                <div class="col-lg-7">
                  <div class="d-grid">
                    <button
                      onClick={nextHandler}
                      class={premiumUser ? "btn btn-golden d-flex align-items-center justify-content-between" : "btn btn-primary btn-arrow"}
                    >
                      {t("tournaments.next")}
                      {premiumUser &&
                        <img src={arrow} />
                      }
                    </button>
                  </div>
              
                </div>
                <div className="col-12 testadds mt-4">
                    <div
                      id="gpt-passback-gamerji.com.Banner0.1732793637"
                      style={{
                        width: '100%',
                        height: 'auto',
                        // position: "fixed",
                        bottom: '0',
                        zIndex: '9999',
                        // background: "red",
                      }}
                    >
                      {/* The ad slot will be displayed here */}
                    </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TournamentModal;
