import React, { useEffect, useState } from 'react'
import BaseModalSubscription from './BaseModalSubscription'
import { Link, useNavigate } from 'react-router-dom'
import { useProfileDataContext } from '../HomePage/ProfileContext'
import { Trans, useTranslation } from "react-i18next";
import { routes } from '../../Utilities/routesFb';
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
import { func } from "../../Utilities/logFunc";
const ShowFreeContestModal = ({showPremiumPopupContest,setShowPremiumPopupContest,title,setShowJVC,contest, tournaments}) => {
  useEffect(() => {
    EventTracking();
  }, []);

  const EventTracking = () => {
    try {
      if (title == "contest") {
        if (contest != undefined && contest != null) {
          eventTracking(events.FREE_CONTEST_NON_PREMIUM, {
            GJ_GameName: contest?.gameAry?.[0]?.name,
            GJ_GameID: contest?._id,
            GJ_ContestID: contest?.gameAry?.[0]?._id,
          });
        }
      }
      else {
        if (tournaments != undefined && tournaments != null) {
          eventTracking(events.FREE_TOURNAMENT_NON_PREMIUM, {
            GJ_GameName: tournaments?.gameAry?.[0]?.name,
            GJ_GameID: tournaments?.gameAry?.[0]?._id,
            GJ_TournamentID: tournaments?._id
          });
        }
      }
    } catch (e) { }
  }

  const {t} = useTranslation()
  const { nonPremiumUser, updatePremiumUser } = useProfileDataContext()
// console.log(nonPremiumUser)
  const [showModal, setShowModal] = useState(showPremiumPopupContest)
  const titleText = title == "contest" ? t('premium.contest') : t('premium.tournament')

  // console.log("titleText",titleText)

  const handleClose = () => {
    setShowModal(false)
    setShowPremiumPopupContest((prev) => false);
  if(setShowJVC)
  setShowJVC(false)
  };
  const navigate = useNavigate()
      const buttonHandler = ()=>{
        setShowPremiumPopupContest(false)
        setShowModal(false)
        navigate('/home/get-premium')
      }
      useEffect(() => {
        // Load the GPT library
        const gptScript = document.createElement('script');
        gptScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'; 
        gptScript.async = true;
        document.body.appendChild(gptScript);
      
        // Initialize the ad slot after the script loads
        gptScript.onload = () => {
          window.googletag = window.googletag || { cmd: [] };
          window.googletag.cmd.push(() => {
            window.googletag.defineSlot(
              '/23081990290,23206251223/gamerji.com.Banner0.1732793637', 
              [[728,90],"fluid"],
              'gpt-passback-gamerji.com.Banner0.1732793637'
            ).addService(window.googletag.pubads());
      
            window.googletag.enableServices();
            window.googletag.display('gpt-passback-gamerji.com.Banner0.1732793637');
            
            // // Set state to true once the ad is displayed
          // setIsAdLoaded(true);
          });
        };
      
        // Clean up the script tag on component unmount
        return () => {
          document.body.removeChild(gptScript);
        };
      }, []);
      func("location", window.location.pathname.split('/'));
  return (
    <><BaseModalSubscription showModal={showModal} setShowModal={setShowModal} text={t('premium.get_gamerji_premium')} handlerClose={handleClose} buttonHandler={buttonHandler}>
      <h6 className='mt-2 d11-premium-h6 text-white'>
        {/* You have already joined {nonPremiumUser?.noOfFreeContest} free {title} today! */}
        {/* {console.log( title == "contest" ?  nonPremiumUser?.noOfFreeContest : nonPremiumUser?.noOfFreeTournament)} */}
        {t('premium.you_have_already_joined_today', { total: title == "contest" ? nonPremiumUser?.noOfFreeContest : nonPremiumUser?.noOfFreeTournament, title: titleText })}
      </h6>
      <h6 className='mt-3 d11-premium-h6 text-white'>
        <p>
          {t('premium.to_join_more_contest')}
        </p>
        <p>
          {/* {t('premium.subscribe_to')} */}
          <Trans
            i18nKey="premium.subscribe_to"
            t={t}
            components={[<Link className="link" to={"/home/" + routes.getSubscriptionTypeList}> {t('premium.subscribe_to')} </Link>]} />
        </p>
        {/* <Trans
        i18nKey="premium.subscribe_to"
        t={t}
        components={[<Link to={'/home/get-premium'} style={{ color: "var(--accent-color)"  }}> {t('premium.mobily_premium')} </Link>]}
      /> */}
      </h6>
      <h6 className='d11-premium-h6 text-white'>{t('premium.premium_users_also_get_access_to_a_host_additional_benefits')}</h6>
      <div className="col-12 testadds mt-4">
        <div
          id="gpt-passback-gamerji.com.Banner0.1732793637"
          style={{
            width: '100%',
            height: 'auto',
            // position: "fixed",
            bottom: '0',
            zIndex: '9999',
            // background: "red",
          }}
        >
          {/* The ad slot will be displayed here */}
        </div>
      </div>

    </BaseModalSubscription>
 </>
  )
}

export default ShowFreeContestModal